<template>
  <div style="width: 100%;">
    <div :class="{ 'has-error': validation.hasError('offerLetter') }" class="form--group">
      <div class="left--col mb-2">
        <label for="dropzone-offer-letter">
          <span>{{ $t('general.offerLetter') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col" style="margin-top: 8px;">
        <div class="custom-control custom-radio mb-2">
          <input
            type="radio"
            id="type1"
            name="customRadio"
            v-model="offerLetterType"
            value="default"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="type1">{{
            $t('general.defaultOfferLetter')
          }}</label>
        </div>
        <div class="custom-control custom-radio mb-2">
          <input
            type="radio"
            id="type2"
            name="customRadio"
            v-model="offerLetterType"
            value="custom"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="type2">{{
            $t('general.customOfferLetter')
          }}</label>
        </div>
        <div class="col-12" id="popOverClauses" v-if="offerLetterType === 'default'">
          <div class="mb-4">
            {{ $t('general.offererReq') }}
          </div>
          <div class="clause--wrapper">
            <clause
              v-for="(clause, index) in clauses"
              :key="index"
              :index="index"
              :clause="clause"
              ref="clauseField"
              :clauseLength="clauses.length"
              commitString="checkout/billingInfo"
            ></clause>
          </div>
          <div class="col-12 p-0">
            <button
              :disabled="clauses.length >= 10"
              @click="addClause"
              class="btn btn-primary btn-block"
              type="button"
              id="clauseBtn"
            >
              {{ $t('booking.addClause') }}
            </button>
          </div>
          <hr />

          <div class="col-12 p-0">
            <div class="btn btn-primary--outline main--btn mt-0" @click="previewOfferLetter">
              <img src="@/assets/img/icons/document.svg" class="mr-2 mb-0" />
              <span>{{ $t('general.seeOfferLetter') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="offerLetterType === 'custom'">
      <vue-dropzone
        ref="offerLetterDropzone"
        id="dropzone-offer-letter"
        :options="dropzoneOptions"
        @vdropzone-file-added="added"
        @vdropzone-sending="sending"
        @vdropzone-success="success"
      />
    </div>
    <span class="val-error mt-2">{{ validation.firstError('offerLetter') }}</span>
  </div>
</template>

<script>
import vue2Dropzone from 'nuxt-dropzone';
import 'nuxt-dropzone/dropzone.css';
import { Validator } from 'simple-vue-validator';
import { mapState } from 'vuex';
const Clause = () => import('@/components/application-action/modals/booking-request/clause-field');

export default {
  name: 'ktp-photo',
  components: {
    vueDropzone: vue2Dropzone,
    Clause,
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/v2/file_content/upload_pdf`,
        maxFilesize: 10,
        maxFiles: 1,
        acceptedFiles: '.pdf',
        paramName: 'file',
      },
      offerLetterPreview: null,
    };
  },
  computed: {
    ...mapState({
      listingUuid: state => state.checkout.listingUuid,
      clauses: state => state.checkout.billingInfo.clauses,
    }),
    offerLetter: {
      get() {
        return this.$store.state.checkout.billingInfo.offerLetter;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_OFFER_LETTER', val);
      },
    },
    offerLetterType: {
      get() {
        return this.$store.state.checkout.billingInfo.offerLetterType;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_OFFER_LETTER_TYPE', val);
      },
    },
  },
  validators: {
    offerLetter(value) {
      if (this.offerLetterType === 'default') {
        return Validator.value(true);
      } else if (this.offerLetterType === 'custom') {
        return Validator.value(value).required(this.$i18n.t('errors.offer_letter.required'));
      }
    },
  },
  methods: {
    validate: function() {
      return this.$validate().then(
        function(success) {
          return !!success;
        }.bind(this),
      );
    },
    added() {
      const dropzone = this.$refs.offerLetterDropzone;
      if (dropzone.dropzone.files.length > 1) {
        dropzone.removeFile(dropzone.dropzone.files[0]);
      }
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);
    },
    // eslint-disable-next-line no-unused-vars
    success(file, response) {
      console.log('RESPONSE SUCCESS upload', response);
      this.offerLetter = response.data;
    },
    removeFile() {
      this.offerLetter = null;
    },
    addClause() {
      this.$store.commit('checkout/billingInfo/ADD_CLAUSE');
      this.$nextTick(function() {
        this.$refs.clauseField[this.clauses.length - 1].$el.querySelector('textarea').focus();
      });
    },
    async getOfferLetterPreview() {
      let result = null;
      result = await this.$store.dispatch('checkout/billingInfo/previewOfferLetter', {
        listingUuid: this.listingUuid,
      });
      if (result && result.template) {
        this.offerLetterPreview = result.template;
      }
    },
    async previewOfferLetter() {
      await this.getOfferLetterPreview();
      this.$modal.show('preview-digital-agreement-modal', {
        digitalSignPreview: this.offerLetterPreview,
        type: 'offer-letter',
      });
    },
  },
};
</script>

<style scoped></style>
